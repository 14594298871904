import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('tab', ['currentStat']),
  },

  methods: {
    nextStep() {
      if (this.currentStat < this.steps.length) {
        for (let i = 0; i <= this.currentStat; i++) {
          this.steps[i].active = true
          if (this.steps[i].active === true) {
            if (i !== 0) {
              this.steps[i - 1].markStatus = true
            }
          }
        }
      }
      if (this.currentStat <= 4) {
        this.$store.commit('tab/SET_CURRENT_STAT', (this.currentStat += 1))
      }
    },
    prevStep() {
      if (this.currentStat > 1) {
        this.$store.commit('tab/SET_CURRENT_STAT', (this.currentStat -= 1))
      }
      if (this.currentStat > 0) {
        for (let i = 0; i <= this.currentStat; i++) {
          this.steps[i].active = true
        }
        this.steps[this.currentStat].active = false
        this.steps[this.currentStat].markStatus = false
        this.steps[this.currentStat - 1].markStatus = false
      }
    },
  },
}
