<template>
  <div v-if="currentStat <= steps.length && !edit" class="sm:text-sm text-xs mb-8 flex w-full">
    <div
      v-for="(step, index) in steps"
      :key="index"
      class="flex gap-1.5 items-center active:text-white px-2.5 py-5 sm:px-4 h-[60px] sm:py-5 w-1/2 sm:w-1/3 border border-primary-gray-200 border-solid"
      :class="{
        active: step.active,
        'bg-primary-purple-50 border-b-2 border-primary-purple-600 border-r-0 border-l-0 border-t-0':
          currentStat === index + 1,
      }"
    >
      <div
        v-if="!step.active"
        class="font-medium min-w-[20px] min-h-[20px] flex items-center justify-center text-primary-gray-400 border-text-primary-gray-400 border-2 border-solid rounded-full"
      ></div>
      <span v-else class="iconBlock">
        <icon icon="checkCircle" />
      </span>
      <span
        class="font-roboto font-medium text-primary-gray-700 pt-0.5"
        :class="{
          'text-primary-purple-600': currentStat === index + 1,
        }"
      >
        {{ step.info }}
      </span>
    </div>
  </div>
</template>

<script>
import icon from '@components/icons/icon.vue'
import { mapState } from 'vuex'

export default {
  components: {
    icon,
  },
  props: {
    edit: { type: Object, default: () => {} },
    steps: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapState('tab', ['currentStat']),
  },
}
</script>
