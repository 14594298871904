<template>
  <SetupLayout
    title="Lets Get Started"
    description="Welcome to MyQampus. Where you can automate your routine institute related work"
    :image="steps[currentStat - 1].image"
  >
    <ValidationObserver @submit="submitForm">
      <UIStepper :steps="steps" />
      <div v-if="currentStat === 1">
        <InputBox
          v-model="instituteData.institute_name"
          type="text"
          name="Institute Name"
          title="Institute Name"
          label="Institute Name"
          placeholder="Institute Name"
          rules="required"
        />
        <div class="flex flex-1 flex-wrap">
          <div class="w-full flex justify-between items-center">
            <div class="w-full flex flex-col">
              <InputBox
                id="logo"
                type="file"
                name="Logo"
                title="Logo"
                label="Logo"
                class="flex-1 border-none"
                :hidden="true"
                rules="size:500"
                :file-src="instituteLogo"
                placeholder="Choose File"
                :tooltip-prop="true"
                :height="13"
                :width="13"
                @change="uploadLogo"
                @clearValue="clearSrcFileLogo"
              />
            </div>
            <p class="pt-1 text-xs ml-4 text-text-color-grey">jpeg/png/jpg</p>
          </div>
          <p class="text-xs -mt-3 mb-4 text-text-color-grey">Ideally image should be 500KB</p>
        </div>
        <div class="flex-1 flex justify-between items-center">
          <div class="w-full flex flex-col">
            <InputBox
              id="favicon"
              type="file"
              name="Favicon"
              title="Favicon"
              label="Favicon"
              :file-src="faviconIcon"
              class="flex-1 border-none"
              :hidden="true"
              :rules="imageRules"
              placeholder="Choose File"
              :tooltip-prop="true"
              :height="13"
              :width="13"
              @change="uoloadFavicon"
              @clearValue="clearSrcFileFavicon"
            />
          </div>
          <p class="pt-1 text-xs ml-4 md:mr-4 text-text-color-grey">
            {{ RULE_CONSTANTS.FILE_TYPES }}
          </p>
        </div>
        <Divider />
        <h3 class="mt-8 sm:mt-6 pb-5 text-lg leading-8 font-semibold text-primary-gray-700">
          Contact Info
        </h3>

        <InputBox
          v-model.trim="instituteData.email"
          type="email"
          name="Email"
          title="Email"
          label="Email"
          placeholder="Email"
          rules="required"
        />
        <PhoneNumber
          v-model.trim="instituteData.contact_no"
          title="Phone Number"
          rules="validNumber"
          class="flex-1"
        />
        <InputBox
          v-model.trim="instituteData.address"
          type="text"
          name="Address"
          title="Address"
          label="Address"
          placeholder="Address"
        />
      </div>
      <div v-if="currentStat === 2">
        <div class="flex-1">
          <InputBox
            v-model="campusData.title"
            type="text"
            name="Campus Name"
            title="Campus Name"
            label="Campus Name"
            placeholder="Campus Name"
            rules="required"
          />
          <InputBox
            v-model="campusData.description"
            type="text"
            name="Description"
            title="Description"
            label="Description"
            placeholder="Description"
          />
        </div>
      </div>
      <div v-if="currentStat === 3">
        <div class="flex-1">
          <InputBox
            v-model="classSection.title"
            type="text"
            name="Class Name"
            title="Class Name"
            label="Class Name"
            placeholder="Class Name"
          />
          <InputBox
            v-model="classSection.section.title"
            type="text"
            name="Section"
            title="Section"
            label="Section"
            placeholder="Section"
          />
        </div>
      </div>
      <template v-if="currentStat < 4">
        <div class="flex justify-between pt-1.5">
          <UIButton @click="signOut">Logout</UIButton>
          <UIButton button="primary">
            <div v-if="false">
              <Loader></Loader>
            </div>
            <div v-else>
              <span v-if="currentStat < 3">Next</span>
              <span v-else>Confirm</span>
            </div>
          </UIButton>
        </div>
      </template>
    </ValidationObserver>
  </SetupLayout>
</template>

<script>
import { mapActions } from 'vuex'
import SetupLayout from '@router/layouts/setup-layout/SetupSliderLayout.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import PhoneNumber from '@components/UiElements/PhoneNumber.vue'
import AuthMixins from '@src/mixins/auth-mixin.js'
import { Form as ValidationObserver } from 'vee-validate'
import UIStepper from '@src/components/UiElements/UIStepper.vue'
import TabMixins from '@src/mixins/components/tab-mixin.js'
import Divider from '@src/components/Divider.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import RULE_CONSTANTS from '@src/constants/rule.constants.js'

export default {
  components: {
    SetupLayout,
    UIStepper,
    InputBox,
    Loader,
    Divider,
    ValidationObserver,
    PhoneNumber,
    UIButton,
  },
  mixins: [AuthMixins, TabMixins],
  page: {
    title: 'Setup',
    meta: [{ name: 'description', content: 'Setup Slider for MyQampus' }],
  },

  data() {
    return {
      RULE_CONSTANTS,
      instituteTitle: JSON.parse(localStorage.getItem('instituteTitle')),
      currentUser: JSON.parse(localStorage.getItem('auth.currentUser')),
      steps: [
        {
          stat: 1,
          info: 'General Settings',
          active: true,
          markStatus: false,
          image: 'general settings',
        },
        { stat: 2, info: 'Add Campus', active: false, markStatus: false, image: 'add campus' },
        { stat: 3, info: 'Add Class', active: false, markStatus: false, image: 'add class' },
      ],
      faviconIcon: '',
      instituteLogo: '',
      instituteData: {
        contact_no: '',
        email: '',
        address: '',
        institute_name: '',
      },
      campusData: {
        title: 'Default',
        description: '',
      },
      classSection: {
        title: '',
        section: {
          title: '',
        },
      },
      isSettingsNull: false,
      redirectRoute: '',
    }
  },
  computed: {
    imageRules() {
      return `${RULE_CONSTANTS.SIZE}`
    },
  },
  created() {
    this.continueSlider()
    this.setCurrentUserData(this.instituteTitle, this.currentUser)
  },
  methods: {
    clearSrcFileLogo() {
      this.instituteLogo = ''
    },
    clearSrcFileFavicon() {
      this.faviconIcon = ''
    },
    /**
     * Set previous step inactive and mark its status true
     * Set next step active and mark its status false
     */
    /**
     * Submit form with student data
     * If edit existing student call editExistingStudent
     * If add new student call addNewStudent function
     * @param {Boolean} invalid - form data validation
     */
    submitForm() {
      /* Student phone number logic */
      if (this.currentStat === 1) {
        this.instituteSetup(this.instituteData).then(() => {
          if (this.isSettingsNull) this.goToDashboard()
          else this.nextStep(1)
        })
      } else if (this.currentStat === 2) {
        this.campusSetup(this.campusData).then((response) => {
          this.campusId = response?.id
          // Update Campus Count
          let stats = JSON.parse(localStorage.getItem('institutesCounts'))
          stats.campuses_count++
          localStorage.setItem('institutesCounts', JSON.stringify(stats))
          this.nextStep(2)
        })
      } else if (this.currentStat === 3) {
        if (this.classSection.title === '' && this.classSection.section.title === '')
          return this.goToDashboard()
        this.sectionSetup(this.classSection).then((res) => {
          this.goToDashboard()
        })
      }
    },

    /**
     * Upload Institute Logo
     * @param {Object} File
     */

    uploadLogo(file) {
      const formData = new FormData()
      formData.append('image', file)
      this.setInstituteLogo({ payload: formData }).then((response) => {
        this.instituteLogo = response?.data?.image
        this.$store.commit('settings/GS_SET_LOGO', response?.data?.image)
      })
    },

    /**
     * Upload Favivon icon
     * @param {Object} File
     */

    uoloadFavicon(file) {
      const formData = new FormData()
      formData.append('favicon', file)
      this.setInstituteFaviconIcon({ payload: formData }).then((response) => {
        this.faviconIcon = response?.data?.favicon
        this.$store.commit('settings/GS_SET_FAVICON', response?.data?.favicon)
      })
    },

    continueSlider() {
      let stats = JSON.parse(localStorage.getItem('institutesCounts'))
      let settings = JSON.parse(localStorage.getItem('setting'))
      if (
        (stats && stats.campuses_count && !settings) ||
        (stats && stats.campuses_count && !settings.length)
      ) {
        this.isSettingsNull = true
      } else if (stats?.campuses_count) {
        for (var i = 0; i < 2; i++) {
          this.nextStep(i + 1)
        }
      }
    },
    goToDashboard() {
      localStorage.setItem('signUpSlider', false)
      localStorage.removeItem('institutesCounts')
      localStorage.removeItem('institutesTitle')
      this.getCampusList()
      this.$router?.push({ name: 'quick-actions' })
      this.isSettingsNull = false
    },
    setCurrentUserData(title, user) {
      this.instituteData.institute_name = title ? title : ''
      this.instituteData.email = user?.email
      this.instituteData.contact_no = user?.phone
      this.instituteData.address = user?.profile?.address
    },
    ...mapActions('institute', [
      'instituteSetup',
      'campusSetup',
      'sectionSetup',
      'setInstituteLogo',
      'setInstituteFaviconIcon',
    ]),
    ...mapActions('layout', ['getCampusList']),
  },
}
</script>

<style lang="scss" module>
@import '@src/router/views/auth/auth.scss';
.input-field input {
  background-color: var(--input-bg-color) !important;
}
label {
  justify-content: flex-start;
}
</style>
