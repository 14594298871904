<template>
  <div class="bg-white">
    <div class="bg-primary-white min-h-screen py-14 px-4 sm:px-0 w-[375px] sm:w-[500px] mx-auto">
      <div class="flex flex-col sm:gap-3 gap-2 mb-6 sm:mb-8">
        <h1
          class="font-inter font-semibold text-primary-gray-900 text-2xl sm:text-3xl sm:leading-[38px] text-center"
        >
          {{ title }}
        </h1>
        <p class="font-inter font-normal text-sm sm:text-base text-primary-gray-500 text-center">
          {{ description }}
        </p>
      </div>
      <form>
        <slot />
      </form>
    </div>
  </div>
</template>

<script>
import generalUtil from '@src/mixins/general-mixins.js'
export default {
  mixins: [generalUtil],
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
}
</script>
